import { axios } from 'utils/axios';
import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { EducationStatus, School, User } from '@novaheal/types';
import { useUser } from 'utils/auth';
import { toastContent } from 'config/toast';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { ToastVariant } from 'types/Toast';
import { EmailStatusType, SuccessStatusType } from 'types/StatusTypes';

export type UpdateUserDto = {
  firstName: string;
  lastName: string;
  b2bSchool?: School;
  b2cSchool: School;
  educationStatus: EducationStatus;
  email?: string;
};

export const updateUserData = async (data: UpdateUserDto): Promise<User> => {
  return axios.patch(`user/me`, { ...data });
};

export const useUpdateUser = (): UseMutationResult<User, unknown, UpdateUserDto, unknown> => {
  const { refetch: refetchUser } = useUser();
  const toast = useNovaToast();
  return useMutation({
    onSuccess: () => {
      toast.show(toastContent.success[SuccessStatusType.CHANGED_DATA]);
      refetchUser();
    },
    onError: (error) => {
      const axiosError = error as AxiosError;
      if (axiosError?.response?.status !== 422)
        toast.show({
          variant: ToastVariant.ERROR,
        });
    },
    mutationFn: updateUserData,
  });
};

export const updateUserEmail = async (email: string): Promise<boolean> => {
  return axios.post(`user/email`, { email });
};

export const useUpdateUserEmail = (
  showToast = true
): UseMutationResult<boolean, unknown, string, unknown> => {
  const { refetch: refetchUser } = useUser();
  const toast = useNovaToast();
  return useMutation({
    onSuccess: (data: any) => {
      if (showToast) {
        if (data === 'USED') toast.show(toastContent.email[EmailStatusType.TAKEN]);
        else toast.show(toastContent.success[SuccessStatusType.CHANGED_DATA]);
      }
      refetchUser();
    },
    onError: (error) => {
      const axiosError = error as AxiosError;
      if (axiosError?.response?.status !== 422 && axiosError?.response?.status !== 400)
        toast.show({
          variant: ToastVariant.ERROR,
        });
    },
    mutationFn: updateUserEmail,
  });
};
