import React, { useState } from 'react';
import { Box, Button, Flex, Collapse, Text } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FieldError } from 'react-hook-form';
import { Form, InputField } from 'components/common/Form';
import * as Sentry from '@sentry/react';
import { useUpdateUserEmail } from 'hooks/api/backend/user/updateUserData';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { useUser } from 'utils/auth';
import { toastContent } from 'config/toast';
import { axios, caughtErrorGlobally } from 'utils/axios';
import { ArrowCircleIcon, EnvelopeIcon } from 'assets/icons/Icons';
import { colors } from 'styles/theme/colors';
import Joi from 'joi';
import { ToastVariant } from 'types/Toast';
import { errorMessage } from './Form/ErrorMessages';

type EditUserEmailInputs = {
  newMail: string;
};

const emailSchema = Joi.object({
  newMail: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .required()
    .messages({
      'string.empty': errorMessage.empty,
      'string.email': errorMessage.email,
    }),
});

export const ChangeMailDropout = (): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync: updateUserEmail } = useUpdateUserEmail(false);
  const toast = useNovaToast();
  const { refetch: refetchUser } = useUser();

  const onEmailSubmit = async (values: EditUserEmailInputs): Promise<void> => {
    if (!values.newMail) return;

    try {
      const isValid = await handleEmailValidationChangeSubmit(values.newMail);
      if (isValid) {
        const result = await updateUserEmail(values.newMail);
        if (result) {
          toast.show(toastContent.email.changeSuccess);
          await refetchUser();
        }
      }
      setIsOpen(false);
    } catch (error) {
      if (!caughtErrorGlobally(error as AxiosError)) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 400) {
          if (axiosError?.response?.data === 'NOT_ALLOWED')
            // Not allowed means you are using the social login
            toast.show(toastContent.email.notAllowed);
        }
      }
    }
  };

  const handleEmailValidationChangeSubmit = async (email: string): Promise<boolean> => {
    try {
      const validateMail = await axios.post('/auth/validate', {
        email,
      });

      if (validateMail.data === 'USED') {
        toast.show(toastContent.email.taken);
        return false;
      }
      return true;
    } catch (error) {
      toast.show({ ...toastContent.error.default, variant: ToastVariant.ERROR });
      Sentry.captureException(error);
      return false;
    }
  };

  return (
    <Box w="100%" maxW="300px">
      <Flex
        alignItems="center"
        justifyContent="center"
        onClick={() => setIsOpen(!isOpen)}
        cursor="pointer"
        gap="8px"
      >
        <ArrowCircleIcon
          width="20px"
          height="20px"
          color={colors.blue.highlighted}
          style={{
            rotate: isOpen ? '0deg' : '180deg',
          }}
        />
        <Text color="blue.highlighted">E-Mail Adresse ändern</Text>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Flex mt={4} direction="column" gap={2} justifyContent="center" alignItems="center">
          <Form<EditUserEmailInputs, typeof emailSchema>
            onSubmit={onEmailSubmit}
            schema={emailSchema}
            autoComplete="on"
            options={{
              reValidateMode: 'onChange',
            }}
          >
            {({ register, formState }) => {
              return (
                <Flex flexDir="column" alignItems="center" gap={2}>
                  <InputField
                    type="email"
                    error={formState.errors.newMail as FieldError}
                    registration={register('newMail')}
                    leftElement={<EnvelopeIcon color="grey.40" />}
                    placeholder="Neue E-Mail Adresse"
                  />
                  <Text textStyle="body.12.reg" color="grey.secondary" textAlign="center">
                    Die eingegebene E-Mail wird nach der Aktualisierung für die Anmeldung verwendet.
                  </Text>
                  <Button mt={2} variant="secondary" type="submit" width="fit-content">
                    E-Mail ändern und senden
                  </Button>
                </Flex>
              );
            }}
          </Form>
        </Flex>
      </Collapse>
    </Box>
  );
};
