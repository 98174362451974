import {
  EmailStatusType,
  ErrorStatusType,
  PasswordStatusType,
  SuccessStatusType,
  VoucherStatusType,
  MiscStatusType,
  UserUpdateStatusType,
} from 'types/StatusTypes';
import { VoucherUsageStatusType } from '@novaheal/types';
import { ToastVariant } from 'types/Toast';

export const toastDuration = 3000;

// vouchers are union of VoucherUsageStatusType and VoucherStatusType due to the missing ability to extend enums
export const toastContent = {
  email: {
    [EmailStatusType.SEND]: {
      title: 'Email versendet',
      variant: ToastVariant.SUCCESS,
    },
    [EmailStatusType.NOT_FOUND]: {
      title: 'Es wurde kein Account zu dieser E-Mail Adresse gefunden.',
      variant: ToastVariant.ERROR,
    },
    [EmailStatusType.NOT_VERIFIED]: {
      title: 'E-Mail bestätigen',
      description: 'Bitte bestätige deinen Account.',
      variant: ToastVariant.WARNING,
    },
    [EmailStatusType.ALREADY_SEND]: {
      title: 'Wir haben dir bereits einen Link an deine E-Mail-Adresse geschickt.',
      variant: ToastVariant.ERROR,
    },
    [EmailStatusType.TAKEN]: {
      title: 'Diese E-Mail-Adresse ist bereits vergeben.',
      description: 'Bitte nutze eine andere Mail oder logge dich ein.',
      variant: ToastVariant.ERROR,
    },
    [EmailStatusType.NOT_ALLOWED]: {
      title: 'Änderung der E-Mail-Adresse nicht möglich.',
      description: 'Du nutzt SOCIAL Login und die E-Mail-Adresse kann nicht geändert werden.',
      variant: ToastVariant.ERROR,
    },
    [EmailStatusType.CHANGE_SUCCESS]: {
      title: 'E-Mail-Adresse erfolgreich geändert',
      description:
        'Bitte bestätige die neue E-Mail um Novaheal im vollen Umfang nutzen zu können.',
      variant: ToastVariant.SUCCESS,
    },
  },
  error: {
    [ErrorStatusType.DEFAULT]: {
      title: 'Hier ist etwas schiefgelaufen.',
      description: 'Falls dieser Fehler häufiger auftritt, wende dich bitte an unseren Support 🙂',
    },
    [ErrorStatusType.GOOGLE_LOGIN]: {
      title: 'Der Google Login hat nicht funktioniert.',
      description: 'Falls dieser Fehler häufiger auftritt, wende dich bitte an unseren Support 🙂',
      variant: ToastVariant.ERROR,
    },
    [ErrorStatusType.APPLE_LOGIN]: {
      title: 'Der Apple Login hat nicht funktioniert.',
      description: 'Falls dieser Fehler häufiger auftritt, wende dich bitte an unseren Support 🙂',
      variant: ToastVariant.ERROR,
    },
    [ErrorStatusType.LOGIN]: {
      title: 'Der Login hat nicht funktioniert.',
      description: 'E-Mail unbekannt oder Passwort falsch.',
      variant: ToastVariant.ERROR,
    },
    [ErrorStatusType.SERVER_ERROR]: {
      title: 'Technischer Fehler',
      description: 'Falls dieser Fehler häufiger auftritt, wende dich bitte an unseren Support 🙂',
      variant: ToastVariant.ERROR,
    },
  },
  success: {
    [SuccessStatusType.DEFAULT]: {
      title: 'Das hat geklappt! 👍',
      variant: ToastVariant.SUCCESS,
    },
    [SuccessStatusType.CHANGED_DATA]: {
      title: 'Daten geändert',
      description: 'Deine Daten wurden geändert.',
      variant: ToastVariant.SUCCESS,
    },
    [SuccessStatusType.SUBSCRIPTION_CANCEL]: {
      title: 'Erfolgreiche Abmeldung',
      description:
        'Dein Abonnement wurde gekündigt. Wir hoffen, dass du bald wieder zurück kommst!',
      variant: ToastVariant.SUCCESS,
    },
    [SuccessStatusType.EMAIL_VERIFICATION]: {
      id: 'E-Mail',
      title: 'E-Mail bestätigt',
      description: 'Deine E-Mail Adresse wurde bestätigt. Vielen lieben Dank!',
      variant: ToastVariant.SUCCESS,
    },
  },
  password: {
    [PasswordStatusType.WRONG]: {
      title: 'Falsches Passwort',
      description: 'Leider ist das Passwort nicht richtig. Probier es bitte erneut.',
      variant: ToastVariant.ERROR,
    },
    [PasswordStatusType.NO_RESET_TOKEN]: {
      title: 'Kein Token!',
      description: 'Wir bringen dich zurück zum Login.',
      variant: ToastVariant.ERROR,
    },
    [PasswordStatusType.CHANGED]: {
      title: 'Passwort geändert',
      variant: ToastVariant.SUCCESS,
    },
  },
  voucher: {
    [VoucherUsageStatusType.ALREADY_ACTIVE]: {
      title: 'Lizenz nicht verfügbar',
      description: 'Diese Organisations-Lizenz wurde bereits aktiviert.',
    },
    [VoucherUsageStatusType.ALREADY_USED]: {
      title: 'Lizenz-Nutzerlimit erreicht',
      description: 'Das Nutzerlimit für Organisations-Lizenz ist erreicht',
    },
    [VoucherUsageStatusType.EXPIRED]: {
      title: 'Lizenz nicht mehr verfügbar',
      description: 'Die Organisations-Lizenz ist abgelaufen',
    },
    [VoucherUsageStatusType.NOT_AVAILABLE]: {
      title: 'Lizenz nicht verfügbar',
      description: 'Diese Organisations-Lizenz ist nicht verfügbar',
    },
    [VoucherUsageStatusType.SUCCESS]: {
      title: 'Lizenz aktiviert!',
      description: 'Lizenz wurde erfolgreich aktiviert. Viel Spaß!',
    },
    [VoucherStatusType.PREVIOUSLY_PAID_SUB]: {
      title: 'Lizenz aktiviert!',
      description:
        'Dein laufendes Abonnement wurde wegen des Vouchercodes automatisch gekündigt. Bei Fragen melde dich gerne bei unserem Support Team! 😊',
    },
  },
  user: {
    [UserUpdateStatusType.SCHOOL_NAME_AS_VOUCHER]: {
      title: 'Hier ist etwas schiefgelaufen',
      description: 'Ungültiger Schulname',
      variant: ToastVariant.ERROR,
    },
  },
  subscription: {
    checkoutError: {
      id: 'checkout-error',
      title: 'Du hast bereits ein aktives Abonnement',
      description:
        'Dein Aktuelles Abonnement wird derzeit von Apple/Google verwaltet. Bitte kündige das Abonnement zunächst in deinem App Store.',
      variant: ToastVariant.ERROR,
    },
    promoCodeRegisterFlow: {
      id: 'promo-code-register-flow',
      title: 'Du hast einen Promo-Code 🎉',
      description: 'Dein Promo-Code wird nach der Registrierung/Login automatisch angewendet.',
      variant: ToastVariant.SUCCESS,
    },
    couponValidations: {
      couponExpired: {
        id: 'coupon-expired',
        title: 'Dein  Promo-Code ist abgelaufen',
        description: 'Bitte verwende einen anderen  Promo-Code.',
        variant: ToastVariant.ERROR,
      },
      couponRedemptionLimitReached: {
        id: 'coupon-redemption-limit-reached',
        title: ' Promo-Code Einlösungslimit erreicht',
        description: 'Dieser  Promo-Code kann nicht mehr eingelöst werden.',
        variant: ToastVariant.ERROR,
      },
    },
  },
  misc: {
    [MiscStatusType.TRANSLATOR_ACTIVE]: {
      title: 'Übersetzer aktiv',
      description:
        'Bitte beachte, dass es dadurch zu Fehlern oder falschen Übersetzungen kommen kann. Schalte den Übersetzer aus, um Novaheal im vollen Umfang zu nutzen.',
      variant: ToastVariant.WARNING,
    },
  },
};
