export enum EmailStatusType {
  SEND = 'send',
  NOT_FOUND = 'notFound',
  ALREADY_SEND = 'alreadySend',
  TAKEN = 'taken',
  NOT_VERIFIED = 'notVerified',
  NOT_ALLOWED = 'notAllowed',
  CHANGE_SUCCESS = 'changeSuccess',
}

export enum ErrorStatusType {
  DEFAULT = 'default',
  GOOGLE_LOGIN = 'googleLogin',
  LOGIN = 'login',
  APPLE_LOGIN = 'appleLogin',
  SERVER_ERROR = 'serverError',
}

export enum SuccessStatusType {
  DEFAULT = 'default',
  CHANGED_DATA = 'changedData',
  SUBSCRIPTION_CANCEL = 'subscriptionCancel',
  EMAIL_VERIFICATION = 'emailVerified',
}

export enum PasswordStatusType {
  WRONG = 'wrong',
  NO_RESET_TOKEN = 'noResetToken',
  CHANGED = 'changed',
}

export enum VoucherStatusType {
  PREVIOUSLY_PAID_SUB = 'previouslyPaid',
}

export enum UserUpdateStatusType {
  SCHOOL_NAME_AS_VOUCHER = 'schoolNameAsVoucher',
}

export enum MiscStatusType {
  TRANSLATOR_ACTIVE = 'translatorActive',
}
