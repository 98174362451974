import { Box, Button, ModalBody, Image, Text, Link } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { ModalMailVerificationProps, SharedModalProps } from 'types/Modal';
import mailImage from 'assets/common/mail.png';
import { ChangeMailDropout } from 'components/common/ChangeMailDropout';
import { useUser } from 'utils/auth';
import { useAuthApi } from 'hooks/api/backend/auth/useAuthApi';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps & ModalMailVerificationProps;

export const MailVerificationModal = ({
  handleClose,
  isShown,
  shouldGoBack,
  target,
  onClose,
}: Props): ReactElement => {
  const { data: user } = useUser();
  const { resendVerification } = useAuthApi();
  const navigate = useNavigate();

  const onClick = (): void => {
    if (onClose) onClose();
    if (shouldGoBack) {
      navigate(-1);
    }

    if (handleClose) handleClose();
  };

  const handleResend = async (): Promise<void> => {
    await resendVerification();
  };
  return (
    <CustomModal
      isOpen={isShown}
      onClose={onClick}
      isCentered
      target={target}
      maxWidth={{ base: '80%', md: '800px' }}
    >
      <ModalBody display="flex" gap="25px" flexDirection="column" alignItems="center" p="40px 30px">
        <Image src={mailImage} maxWidth="128px" />
        <Text textStyle="h2" color="blue.textHeader">
          Bitte bestätige deine E-Mail Adresse
        </Text>
        <Box display="flex" flexDirection="column" gap="10px">
          <Text textStyle="body.16.reg" color="grey.50" textAlign="center">
            Bitte bestätige deine Email. Überprüfe deinen Posteingang und folge den Anweisungen.
            E-Mail wurde gesendet&nbsp;an:
          </Text>
          <Text textStyle="body.16.med" textAlign="center">
            {user?.email}
          </Text>
        </Box>
        <ChangeMailDropout />
        <Button variant="primary" onClick={handleResend} flexShrink={0}>
          E-Mail erneut senden
        </Button>
        <Text>
          Bei Problemen kontaktieren Sie uns unter:{' '}
          <Link color="blue.highlighted" href="mailto:support@novaheal.de">
            support@novaheal.de
          </Link>
        </Text>
      </ModalBody>
    </CustomModal>
  );
};
